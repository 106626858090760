import React from "react";
import "./au3.scss";

export default () => {
  return (
    <div className="au3 container">
      <div className="au3-left">
        <p>
          Premise Healthcare is a new and dynamic enterprise, we are a focused
          nursing agency concentrating specifically on satisfying you with our
          expertise and extensive knowledge in the nursing industry. We want to
          help you sit back and relax whilst we put in the hard work to find and
          guarantee an ideal position or candidate suited to your request.
        </p>
        <blockquote>
          <p>
            If you are a client looking for a candidate, depend on us to provide
            you the specific skilled and well screened applicant that suits your
            demand. We have an extremely friendly and welcoming team to help
            you, our teams are based on multilingual talented consultants from
            all over the world.
          </p>
        </blockquote>
        <blockquote>
          <p>
            If you are looking for a client to work for, come ask us for our
            latest positions whether they be permanent or adhoc, in the private
            or public sector. We will find you the ideal position. We take pride
            in knowing that we listen to what your needs are. That is the
            difference.
          </p>
        </blockquote>
        <p>
          But enough about us, let us talk about you. Whether you are a
          client/business or a nurse, you need a consultant that takes care of
          the complicated stuff so you can focus on your job.
        </p>
      </div>
      <div className="au3-right">
        <div></div>
      </div>
    </div>
  );
};
