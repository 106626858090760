import React from "react";
import "./au5.scss";

export default () => {
  return (
    <div className="au5">
      <h5>Become an Premise Healthcare Recruiter</h5>
      <p className="container">
        Want to work in an exciting, fast-moving medical recruitment company
        based in Leicester? Think you have what it takes to progress your
        recruitment career and be at the very top of your game? Then simply get
        in touch with us as we are always on the lookout for the most exciting
        recruitment talent.
      </p>
    </div>
  );
};
