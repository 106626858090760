import React from "react";
import Chero from "../components/chero";
import Head from "../components/head";
import Au3 from "../components/pages/about/au3/au3";
import Au4 from "../components/pages/about/au4/au4";
import Au5 from "../components/pages/about/au5/au5";

const RegularPage = () => (
  <>
    <Head
      title="About Us"
      dis="Premise Healthcare is a new and dynamic enterprise, we are a focused nursing agency concentrating specifically on satisfying you with our expertise and extensive knowledge in the nursing industry. "
    />
    <Chero herotitle="About Us" />
    {/* <Au1 /> */}
    <Au3 />
    <Au4 />
    <Au5 />
    {/* <Au2 /> */}
    {/* <Au6 /> */}
  </>
);

export default RegularPage;
